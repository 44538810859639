import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";

export default function Privacy() {
  return (
    <>
      <TopNavbar />
      <h2 className="title txt-black">Our Privacy Policy</h2>
      <section
        class=""
        style={{
          marginTop: 40,
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 32,
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <small className="txt-black">
                Last Revised: <strong>March 04, 2024</strong>
              </small>
              <p>&nbsp;</p>
              <div class="textSection">
                <p className="txt-black">
                  Welcome to Pass's Privacy Policy. Thank you for taking the
                  time to read it. At Pass, we make your privacy and protection
                  of your data a top priority.
                </p>
                <p className="txt-black">
                  We appreciate that you trust us with your information and we
                  intend to always keep that trust. This starts with making sure
                  you understand the information we collect, why we collect it,
                  how it is used and your choices regarding your information.
                  This Privacy Policy applies beginning{" "}
                  <strong>March 04, 2024.</strong>
                </p>
                <p className="txt-black">&nbsp;</p>
                <p className="txt-black">
                  <strong>
                    EFFECTIVE DATE: <strong>March 03, 2024</strong>
                  </strong>
                </p>

                <br />

                <h5 className="txt-black">WHERE THIS PRIVACY POLICY APPLIES</h5>
                <p className="txt-black">
                  This Privacy Policy applies to websites and apps offered by
                  Pass. We refer to these as our "services" in this Privacy
                  Policy. We have added links to this Privacy Policy on all
                  applicable services.
                </p>

                <br />

                <h5 className="txt-black">INFORMATION WE COLLECT</h5>
                <p className="txt-black">
                  We collect information you add on our services by using them
                  such as basic profile details. We also collect information
                  generated as you use our services such as access logs, as well
                  as information from third parties such as authentication
                  providers. More details about how and what information we
                  collect are outlined below.
                </p>

                <p className="txt-black">
                  <strong>Information you give us</strong>
                </p>
                <p className="txt-black">
                  You choose to give us certain information when using our
                  services. This includes:
                  <br />
                  <ul>
                    <li className="txt-black">
                      When you create an account, you provide us with at least
                      your login credentials, as well as some basic details
                      necessary for the service to work, such as an email
                      address, phone number, and profile picture.
                    </li>
                    <li className="txt-black">
                      When you need to generate your cards, you provide us with
                      information needed for those cards. By choosing to provide
                      this information, you consent to our processing of that
                      information.
                    </li>
                  </ul>
                </p>

                <p className="txt-black">
                  <strong>Usage Information</strong>
                </p>
                <p className="txt-black">
                  We collect information about your activity on our services,
                  for instance log in activity.
                </p>

                <p className="txt-black">
                  <strong>Device information</strong>
                </p>
                <p className="txt-black">
                  We collect information from and about the device(s) you use to
                  access our services, including:
                  <br />
                  <ul>
                    <li className="txt-black">
                      information on your wireless and mobile network
                      connection, like your service provider and signal
                      strength;
                    </li>
                  </ul>
                </p>

                <p className="txt-black">
                  <strong>Other information with your consent</strong>
                </p>
                <p className="txt-black">
                  <ul>
                    <li className="txt-black">
                      If you give us permission, we may collect your photo(s)
                      (for instance, if you want to upload a profile picture).
                      If you decline permission for us to collect your photo(s),
                      we will not collect them.
                    </li>
                  </ul>
                </p>

                <br />

                <h5 className="txt-black">HOW WE USE INFORMATION</h5>
                <p className="txt-black">
                  The main reason we collect and use your information is to
                  deliver and improve our services. Outlined below are reasons
                  why we collect and how we use your information
                </p>
                <p className="txt-black">
                  <strong>
                    To administer your account and provide our services to you
                  </strong>
                </p>
                <p className="txt-black">
                  <ul>
                    <li className="txt-black">
                      Create and manage your account
                    </li>
                    <li className="txt-black">
                      Provide you with customer support and respond to your
                      requests
                    </li>
                  </ul>
                </p>

                <p className="txt-black">
                  <ul>
                    <li className="txt-black">
                      Display your online profile to the public
                    </li>
                  </ul>
                </p>

                <p className="txt-black">
                  <strong>To ensure legal compliance</strong>
                </p>
                <p className="txt-black">
                  <ul>
                    <li className="txt-black">
                      Comply with legal requirements
                    </li>
                    <li className="txt-black">Assist law enforcement</li>
                    <li className="txt-black">
                      Enforce or exercise our rights, for example our Terms
                    </li>
                  </ul>
                </p>
                <p className="txt-black">
                  We use the information you provide us to deliver, and improve
                  our services to you. You have the right to opt out of this
                  privacy policy by contacting us at the contacts provided at
                  the end of this document.{" "}
                </p>

                <br />

                <h5 className="txt-black">HOW WE SHARE INFORMATION</h5>

                <p className="txt-black">
                  We share your online profile and information with whoever has
                  a link to your profile. Read on for more details about how
                  your information is shared with others.
                </p>
                <p className="txt-black">
                  <strong>With other users</strong>
                </p>
                <p className="txt-black">
                  You share information with other users on Pass apps (and in
                  the case of any sharing features available on Pass, the
                  individuals or apps with whom a Pass user may choose to share
                  you with) when you voluntarily disclose information on the
                  service (including your public profile). Please be careful
                  with your information and make sure that the content you share
                  is information that you're comfortable being publically
                  viewable since neither you nor we can control what others do
                  with your information once you share it.
                </p>

                <p className="txt-black">
                  We may choose to disclose your information if reasonably
                  necessary to:
                  <br />
                  <ul>
                    <li className="txt-black">
                      comply with a legal process, such as a court order,
                      subpoena or search warrant, government / law enforcement
                      investigation or other legal requirements;
                    </li>
                    <li className="txt-black">
                      to assist in the prevention or detection of crime (subject
                      in each case to applicable law); or
                    </li>
                    <li className="txt-black">
                      to protect the safety of any person.
                    </li>
                    <li className="txt-black">To enforce legal rights</li>
                  </ul>
                </p>
                <p className="txt-black">
                  We may also share information:
                  <br />
                  <ul>
                    <li className="txt-black">
                      if disclosure would mitigate our liability in an actual or
                      threatened lawsuit;{" "}
                    </li>
                    <li className="txt-black">
                      as necessary to protect our legal rights and legal rights
                      of our users, business partners or other interested
                      parties;
                    </li>
                    <li className="txt-black">
                      to enforce our agreements with you; and
                    </li>
                    <li className="txt-black">
                      to investigate, prevent, or take other action regarding
                      illegal activity, suspected fraud or other wrongdoing.
                    </li>
                  </ul>
                </p>

                <br />

                <h5 className="txt-black">HOW WE PROTECT YOUR INFORMATION</h5>

                <p className="txt-black">
                  We work hard to protect you from unauthorized access to or
                  alteration, disclosure or destruction of your personal
                  information. Although we take steps to secure your
                  information, we do not promise, and you should not expect,
                  that your personal information will always remain secure.
                </p>
                <p className="txt-black">
                  We regularly monitor our systems for possible vulnerabilities
                  and attacks and regularly review our information collection,
                  storage and processing practices to update our physical,
                  technical and organizational security measures.
                </p>
                <p className="txt-black">
                  We may suspend your use of all or part of the services without
                  notice if we suspect or detect any breach of security. If you
                  believe that your account or information is no longer secure,
                  please notify us immediately at the contact provided at the
                  end of this document.
                </p>

                <br />

                <h5 className="txt-black">PRIVACY POLICY CHANGES</h5>
                <p className="txt-black">
                  This policy may change over time. We will notify you before
                  any material changes take effect so that you have time to
                  review the changes.
                </p>

                <br />

                <h5 className="txt-black">HOW TO CONTACT US</h5>
                <p className="txt-black">
                  If you have questions about this Privacy Policy write to us
                  at:
                </p>
                <p className="txt-black">
                  <a href="mailto:support@pass-card.app">
                    <strong>support@pass-card.app</strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
