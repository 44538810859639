import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";

export default function Terms() {
  return (
    <>
      <TopNavbar />
      <h2 className="title txt-black">Our Terms and Conditions</h2>

      <section
        class=""
        style={{
          marginTop: 40,
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 32,
        }}
      >
        <div class="container">
          <div class="row" style={{ marginBottom: 50 }}>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <small className="txt-black">
                Last Revised: <strong>March 04, 2024.</strong>
              </small>
              <p>&nbsp;</p>
              <div class="textSection">
                <h4 className="txt-black">
                  Welcome to Pass's Terms of Service
                </h4>
                <ol type="1">
                  <li className="txt-black">
                    <h5 className="txt-black">
                      Acceptance of Terms of Use Agreement
                    </h5>
                    <p className="txt-black">
                      By creating a Pass account or by using any other services
                      provided by Pass you agree to be bound by i) these Terms
                      of Use and ii) our Privacy Policy. If you do not accept
                      and agree to be bound by all of the terms of this
                      Agreement, you should not use the Service.
                    </p>
                    <p className="txt-black">
                      We make changes to this Agreement and to the Service from
                      time to time as a result of changes in or requirements of
                      the law, new features added to the service, or changes in
                      business practices. The most recent version of this
                      agreement shall be posted on the Service under “Terms of
                      Service” and also on www.pass-card.app, you should
                      regularly check for the most recent revision of this
                      agreement, as the most recent revision is the one that
                      applies. Should the changes include changes that affect
                      your rights or obligations, we will notify you in advance
                      of the changes by reasonable means which could include
                      notification through the Service. If you continue to use
                      the service after the changes become effective, you agree
                      to the revised Agreement. You also agree that Agreement
                      shall supersede any prior agreements (except as
                      specifically stated herein), and shall govern your entire
                      relationship with Pass, including but not limited to
                      events, agreements, and conduct preceding your acceptance
                      of this Agreement.{" "}
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Eligibility</h5>
                    <p className="txt-black">
                      Any person is allowed to create an account on Pass and use
                      the Service. By creating an account and using the Service,
                      you represent and warrant that:
                    </p>
                    <ul>
                      <li className="txt-black">
                        You can form a binding contract with Pass
                      </li>
                      <li className="txt-black">
                        You are not a person who is barred from using the
                        Service under the laws of the Republic of Rwanda or any
                        other applicable jurisdiction
                      </li>
                      <li className="txt-black">
                        You will comply with this Agreement and all applicable
                        local, national and international laws, rules and
                        regulations
                      </li>
                    </ul>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Your Account</h5>
                    <p className="txt-black">
                      In order to use Pass, you must sign in with your email or
                      Social login providers (Apple, Gmail, and Microsoft). If
                      you do so, you authorize us to send you emails or
                      notifications and agree that you are liable for all the
                      rates that may apply.
                    </p>
                    <p className="txt-black">
                      You are responsible for maintaining the confidentiality of
                      your login credentials you use to sign up for Pass, and
                      you are solely responsible for activities that occur under
                      those credentials. If you think someone has gained access
                      to your account, please immediately contact{" "}
                      <a href="mailto:support@pass-card.app">
                        support@pass-card.app.
                      </a>
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">
                      Modifying the Service and Termination
                    </h5>
                    <p className="txt-black">
                      Pass is always striving to improve the Service and bring
                      you additional functionality that you will find engaging
                      and useful. As a result, we may add new product features
                      or enhancements from time to time as well as remove some
                      features, and if these actions do not materially affect
                      your rights or obligations, we may not provide you with
                      notice before taking them. We may even suspend the Service
                      entirely, in which event we will notify you in advance
                      unless extenuating circumstances, such as safety or
                      security concerns prevent us from doing so.
                    </p>
                    <p className="txt-black">
                      You may terminate your account at any time, for any
                      reason, by emailing us at{" "}
                      <a href="mailto:support@pass-card.app">
                        support@pass-card.app
                      </a>{" "}
                      with your email/username.
                    </p>
                    <p className="txt-black">
                      Pass may terminate your account or put it on hold at
                      anytime without notice if it believes that you have
                      violated this Agreement or other applicable agreements and
                      policies. After your account is terminated, this Agreement
                      will terminate, except that the following provisions will
                      still apply to you and Pass: Section 4, Section 5.
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">
                      Safety; Your interactions with Other Users.
                    </h5>
                    <p className="txt-black">
                      Though Pass strives to encourage a respectful user
                      experience,{" "}
                      <strong>
                        it is not responsible for the conduct of any user on or
                        off the Service. You agree to use caution in all
                        interactions with other users, particularly if you
                        decide to communicate off the Service or meet in person.
                        In addition, you agree to review and follow Pass’s
                        Safety Tips prior to using the Service. You agree that
                        you will not provide your financial information or send
                        money to other users directly as financials through the
                        platform are managed by Pass.
                      </strong>
                    </p>
                    <p className="txt-black">
                      <strong>
                        YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH
                        OTHER USERS. YOU UNDERSTAND THAT Pass DOES NOT CONDUCT
                        CRIMINAL BACKGROUND CHECKS ON ITS USERS OR OTHERWISE
                        INQUIRE INTO THE BACKGROUND OF ITS USERS. Pass MAKES NO
                        REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF
                        USERS.
                      </strong>{" "}
                      Pass RESERVES THE RIGHT TO CONDUCT - AND YOU AGREE THAT
                      Pass MAY CONDUCT - ANY CRIMINAL BACKGROUND CHECK OR OTHER
                      SCREENINGS AT ANY TIME USING AVAILABLE PUBLIC RECORDS.{" "}
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Rights Pass Grants You.</h5>
                    <p className="txt-black">
                      Pass grants you a personal, worldwide, royalty-free,
                      non-assignable, non-exclusive, revocable, and
                      non-sublicensable license to access and use the Service.
                      This license is for the sole purpose of letting you use
                      and enjoy the Service’s benefits as intended by Pass and
                      permitted by this Agreement. Therefore,{" "}
                      <strong>you agree not to</strong>:
                    </p>
                    <ul>
                      <li className="txt-black">
                        post pictures which you do not own full rights to, or
                        copyrighted pictures which do not own full rights to.
                      </li>
                      <li className="txt-black">
                        post pictures which consist of images and branding
                        belonging to political figures and personnels.
                      </li>
                      <li className="txt-black">
                        use the Service or any content contained in the Service
                        for any commercial purposes without written consent.
                      </li>
                      <li className="txt-black">
                        copy, modify, transmit, create any derivative works
                        from, make use of, or reproduce in any way any
                        copyrighted material, images, trademarks, trade names,
                        service marks, or other intellectual property, content
                        or proprietary information accessible through the
                        Service without Pass’s prior written consent.
                      </li>
                      <li className="txt-black">
                        express or imply that any statements you make are
                        endorsed by Pass
                      </li>
                      <li className="txt-black">
                        use any robot, bot, spider, crawler, scraper, site
                        search/retrieval application, proxy or other manual or
                        automatic device, method or process to access, retrieve,
                        index, “data mine,” or in any way reproduce or
                        circumvent the navigational structure or presentation of
                        the Service or its contents.
                      </li>
                      <li className="txt-black">
                        use the Service in any way that could interfere with,
                        disrupt or negatively affect the Service or the servers
                        or networks connected to the Service.
                      </li>
                      <li className="txt-black">
                        upload viruses or other malicious code or otherwise
                        compromise the security of the Service.
                      </li>
                      <li className="txt-black">
                        forge headers or otherwise manipulate identifiers in
                        order to disguise the origin of any information
                        transmitted to or through the Service.
                      </li>
                      <li className="txt-black">
                        “frame” or “mirror” any part of the Service without
                        Pass’s prior written authorization.
                      </li>
                      <li className="txt-black">
                        use meta tags or code or other devices containing any
                        reference to Pass or the Service (or any trademark,
                        trade name, service mark, logo or slogan of Pass) to
                        direct any person to any other website for any purpose.
                      </li>
                      <li className="txt-black">
                        modify, adapt, sublicense, translate, sell, reverse
                        engineer, decipher, decompile or otherwise disassemble
                        any portion of the Service, or cause others to do so.
                      </li>
                      <li className="txt-black">
                        use or develop any third-party applications that
                        interact with the Service or other users’ Content or
                        information without our written consent.
                      </li>
                      <li className="txt-black">
                        use, access, or publish the Pass application programming
                        interface without our written consent.
                      </li>
                      <li className="txt-black">
                        probe, scan or test the vulnerability of our Service or
                        any system or network.
                      </li>
                      <li className="txt-black">
                        encourage or promote any activity that violates this
                        Agreement.
                      </li>
                    </ul>
                    <p className="txt-black">&nbsp;</p>
                    <p className="txt-black">
                      The Company may investigate and take any available legal
                      action in response to illegal and/ or unauthorized uses of
                      the Service, including termination of your account.
                    </p>
                    <p className="txt-black">
                      Any software that we provide you may automatically
                      download and install upgrades, updates, or other new
                      features. You may be able to adjust these automatic
                      downloads through your device’s settings.
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Rights you grant Pass</h5>
                    <p className="txt-black">
                      By creating an account, you grant to Pass a worldwide,
                      transferable, sub-licensable, royalty-free, right and
                      license to host, store, use, copy, display, reproduce,
                      adapt, edit, publish, modify and distribute information
                      you post, upload, display or otherwise make available
                      (collectively, “post”) on the Service or transmit to other
                      users (collectively, “Content”). Pass’s license to your
                      Content shall be non-exclusive. In addition, so that Pass
                      can prevent the use of your Content outside of the
                      Service, you authorize Pass to act on your behalf with
                      respect to infringing uses of your Content taken from the
                      Service by other users or third parties. Our license to
                      your Content is subject to your rights under applicable
                      law (for example laws regarding personal data protection
                      to the extent any Content contains personal information as
                      defined by those laws) and is for the limited purpose of
                      operating, developing, providing, and improving the
                      Service and researching and developing new ones. You agree
                      that any Content you place or that you authorize us to
                      place on the Service may be viewed by other users and may
                      be viewed by any person visiting or participating in the
                      Service (such as individuals who may receive shared
                      Content from other Pass users).
                    </p>
                    <p className="txt-black">
                      You agree that all information that you submit upon
                      creation of your account, including information submitted
                      from your Gmail, Apple, or Microsoft account, is accurate
                      and truthful and you have the right to post the Content on
                      the Service and grant the license to Pass above.
                    </p>
                    <p className="txt-black">
                      You understand and agree that we may monitor or review any
                      Content you post as part of a Service. We may delete any
                      Content, in whole or in part, that in our sole judgment
                      violates this Agreement or may harm the reputation of the
                      Service.
                    </p>
                    <p className="txt-black">
                      When communicating with our customer care representatives,
                      you agree to be respectful and kind. If we feel that your
                      behavior towards any of our customer care representatives
                      or other employees is at any time threatening or
                      offensive, we reserve the right to immediately terminate
                      your account.
                    </p>
                    <p className="txt-black">
                      In consideration for Pass allowing you to use the Service,
                      you agree that we, our affiliates, and our third-party
                      partners may place advertising on the Service. By
                      submitting suggestions or feedback to Pass regarding our
                      Service, you agree that Pass may use and share such
                      feedback for any purpose without compensating you.
                    </p>
                    <p className="txt-black">
                      You agree that Pass may access, preserve and disclose your
                      account information and Content if required to do so by
                      law or in a good faith belief that such access,
                      preservation or disclosure is reasonably necessary, such
                      as to: (i) comply with legal process; (ii) enforce this
                      Agreement; (iii) respond to claims that any Content
                      violates the rights of third parties; (iv) respond to your
                      requests for customer service; or (v) protect the rights,
                      property or personal safety of the Company or any other
                      person.
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Community Rules</h5>
                    <p className="txt-black">
                      By using the Service, you agree that you will not:
                    </p>
                    <ul>
                      <li className="txt-black">
                        use the Service for any purpose that is illegal or
                        prohibited by this Agreement.
                      </li>
                      <li className="txt-black">
                        use the Service for any harmful or nefarious purpose.
                      </li>
                      <li className="txt-black">
                        use the Service in order to damage Pass
                      </li>
                      <li className="txt-black">
                        violate our Community Guidelines, as updated from time
                        to time.
                      </li>
                      <li className="txt-black">
                        spam, solicit money from or defraud any users.
                      </li>
                      <li className="txt-black">
                        impersonate any person or entity or post any designs,
                        works, products, or images of another person or entity
                        without his or her permission.
                      </li>
                      <li className="txt-black">
                        bully, “stalk,” intimidate, assault, harass, mistreat or
                        defame any person.
                      </li>
                      <li className="txt-black">
                        post any Content that violates or infringes anyone’s
                        rights, including rights of publicity, privacy,
                        copyright, trademark or other intellectual property or
                        contract right.
                      </li>
                      <li className="txt-black">
                        post any Content that is hate speech, threatening,
                        sexually explicit or pornographic; incites violence; or
                        contains nudity or graphic or gratuitous violence.
                      </li>
                      <li className="txt-black">
                        post any Content that promotes racism, bigotry, hatred
                        or physical harm of any kind against any group or
                        individual.
                      </li>
                      <li className="txt-black">
                        solicit passwords for any purpose, or personal
                        identifying information for commercial or unlawful
                        purposes from other users or disseminate another
                        person’s personal information without his or her
                        permission.
                      </li>
                      <li className="txt-black">use another user’s account.</li>
                      <li className="txt-black">
                        create another account if we have already terminated
                        your account, unless you have our permission.
                      </li>
                    </ul>
                    <p className="txt-black">
                      Pass reserves the right to investigate and/ or terminate
                      your account without a refund of any purchases if you have
                      violated this Agreement, misused the Service or behaved in
                      a way that Pass regards as inappropriate or unlawful,
                      including actions or communications that occur on or off
                      the Service.
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Other User’s Consent</h5>
                    <p className="txt-black">
                      Although Pass reserves the right to review and remove
                      Content that violates this Agreement, such Content is the
                      sole responsibility of the user who posts it, and Pass
                      cannot guarantee that all Content will comply with this
                      Agreement. If you see Content on the Service that violates
                      this Agreement, please report it within the Service or via{" "}
                      <a href="mailto:support@pass-card.app">
                        {" "}
                        support@pass-card.app
                      </a>
                      .
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Disclaimers</h5>
                    <p className="txt-black">
                      {" "}
                      Pass PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE”
                      BASIS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW,
                      GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS,
                      IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE
                      SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN),
                      INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
                      SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A
                      PARTICULAR PURPOSE OR NON-INFRINGEMENT. Pass DOES NOT
                      REPRESENT OR WARRANT THAT (A) THE SERVICE WILL BE
                      UNINTERRUPTED, SECURE OR ERROR FREE, (B) ANY DEFECTS OR
                      ERRORS IN THE SERVICE WILL BE CORRECTED, OR (C) THAT ANY
                      CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE
                      SERVICE WILL BE ACCURATE.
                    </p>
                    <p className="txt-black">
                      Pass TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR
                      ANOTHER USER OR THIRD PARTY POSTS, SENDS OR RECEIVES
                      THROUGH THE SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE
                      OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT
                      YOUR OWN DISCRETION AND RISK.
                    </p>
                    <p className="txt-black">
                      Pass DISCLAIMS AND TAKES NO RESPONSIBILITY FOR ANY CONDUCT
                      OF YOU OR ANY OTHER USER, ON OR OFF THE SERVICE.
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Third Party Services</h5>
                    <p className="txt-black">
                      The Service may contain advertisements and promotions
                      offered by third parties and links to other web sites or
                      resources. Pass is not responsible for the availability
                      (or lack of availability) of such external websites or
                      resources. If you choose to interact with the third
                      parties made available through our Service, such party’s
                      terms will govern their relationship with you. Pass is not
                      responsible or liable for such third parties’ terms or
                      actions.
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Limitation of Liability</h5>
                    <p className="txt-black">
                      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                      EVENT SHALL Pass, ITS AFFILIATES, EMPLOYEES, LICENSORS OR
                      SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT,
                      CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE,
                      OR ENHANCED DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS
                      OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR
                      ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
                      LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR
                      INABILITY TO ACCESS OR USE THE SERVICE; (II) THE CONDUCT
                      OR CONTENT OF OTHER USERS OR THIRD PARTIES ON, THROUGH OR
                      FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED
                      ACCESS, USE OR ALTERATION OF YOUR CONTENT, EVEN IF MPass
                      HAS BEEN ADVISED AT ANY TIME OF THE POSSIBILITY OF SUCH
                      DAMAGES. THE DAMAGES LIMITATION SET FORTH IN THE
                      IMMEDIATELY PRECEDING SENTENCE APPLIES (i) REGARDLESS OF
                      THE GROUND UPON WHICH LIABILITY IS BASED (WHETHER DEFAULT,
                      CONTRACT, TORT, STATUTE, OR OTHERWISE), (ii) IRRESPECTIVE
                      OF THE TYPE OF BREACH OF OBLIGATIONS, AND (iii) WITH
                      RESPECT TO ALL EVENTS, THE SERVICE, AND THIS AGREEMENT.
                    </p>
                    <p className="txt-black">
                      THE LIMITATION OF LIABILITY PROVISIONS SET FORTH IN THIS
                      SECTION 12 SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS
                      AGREEMENT FAIL WITH RESPECT TO THEIR ESSENTIAL PURPOSE.
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Indemnity by You.</h5>
                    <p className="txt-black">
                      You agree, to the extent permitted under applicable law,
                      to indemnify, defend and hold harmless Pass, our
                      affiliates, and their and our respective officers,
                      directors, agents, and employees from and against any and
                      all complaints, demands, claims, damages, losses, costs,
                      liabilities and expenses, including attorney’s fees, due
                      to, arising out of, or relating in any way to your access
                      to or use of the Service, your Content, or your breach of
                      this Agreement.
                    </p>
                  </li>
                  <li className="txt-black">
                    <h5 className="txt-black">Entire Agreement; Other</h5>
                    <p className="txt-black">
                      This Agreement, along with the Privacy Policy, the Safety
                      Tips, and any terms disclosed to you if you purchase or
                      have purchased additional features, products or services
                      we offer on the Service, contains the entire agreement
                      between you and Pass regarding your relationship with Pass
                      and the use of the Service. If any provision of this
                      Agreement is held invalid, the remainder of this Agreement
                      shall continue in full force and effect. The failure of
                      Pass to exercise or enforce any right or provision of this
                      Agreement shall not constitute a waiver of such right or
                      provision. You agree that your Pass account is
                      non-transferable and all of your rights to your account
                      and its Content terminate upon your death. No agency,
                      partnership, joint venture, fiduciary or other special
                      relationship or employment is created as a result of this
                      Agreement and you may not make any representations on
                      behalf of or bind Pass in any manner.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
