import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="We are delighted knowing these are the last business cards we are ever going to purchase for our team. Guaranteed there are subscriptions fees, but they do not compare at all to the costs and headaches of ordering paper business cards."
            author="Yves Ndejuru"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Pass saves me so much time when sharing my contacts. It is so cool, fast, and so efficient. I am no longer worried about forgetting my business cards when attending events. It is on my phone whenever I need it! I even purchased the NFC card from Pass, yet another cooler medium for me to share my contacts. "
            author="Chris Mugabe"
          />
        </LogoWrapper>

        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Pass digital business cards is a piece of technology you did not know you had to have until you heard about it. I am now very much excited to know I will always have my business cards with me to share anytime, anywhere. I purchased it once and that was it! The fact that I can always change my information and not have to reprint my business cards is such a relief."
            author="Moise Ndabarasa"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
