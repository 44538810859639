import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/img/branding/logo.png";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
              <img src={LogoImg} alt="" width={32} />
              <h1
                className="font15 extraBold whiteColor"
                style={{ marginLeft: "15px" }}
              >
                Pass
              </h1>
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span className="font13">
                Pass <span style={{ fontSize: 10, color: "#808080" }}>by</span>{" "}
                <span>Fabriq.</span>
              </span>{" "}
              All Right Reserved
            </StyleP>

            <Link
              className="whiteColor animate pointer font13"
              to="home"
              smooth={true}
              offset={-80}
            >
              Back to top
            </Link>
          </InnerWrapper>
        </div>
        <div
          className="container"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            paddingBottom: 16,
          }}
        >
          <a href="/terms" className="text-white" style={{ color: "white" }}>
            Terms & Conditions
          </a>
          <a href="/privacy" style={{ color: "white", marginLeft: 8 }}>
            Privacy Policy
          </a>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
