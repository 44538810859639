import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import CheckMark from "../../assets/svg/Checkmark";

import UserIcon from "../../assets/svg/Services/UserIcon";
import OrgIcon from "../../assets/svg/Services/OrgIcon";
import EventIcon from "../../assets/svg/Services/EventIcon";
import CardIcon from "../../assets/svg/Services/CardIcon";

export default function PricingTable({
  icon,
  price,
  title,
  text,
  offers,
  action,
  actionTitle,
}) {
  let getIcon;

  switch (icon) {
    case "user":
      getIcon = <UserIcon />;
      break;
    case "org":
      getIcon = <OrgIcon />;
      break;
    case "event":
      getIcon = <EventIcon />;
      break;
    case "card":
      getIcon = <CardIcon />;
      break;
    default:
      getIcon = <UserIcon />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font20 extraBold">{price}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font20 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div
                className="flexNullCenter"
                style={{ margin: "15px 0" }}
                key={index}
              >
                <div
                  style={{
                    position: "relative",
                    top: "-1px",
                    marginRight: "15px",
                  }}
                >
                  {item.cheked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font14 bold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      {action && (
        <div style={{ maxWidth: "120px", margin: "30px auto 0 auto" }}>
          <FullButton title={actionTitle} action={action} />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
